import { PrivacyPolicyUI } from "components";

const PrivacyPolicy = () => {
  return (
    <>
      <PrivacyPolicyUI />
    </>
  );
};

export { PrivacyPolicy };
