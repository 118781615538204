import { FAQUI } from "components";
import * as React from "react";

const FAQ = () => {
  return (
    <>
      <FAQUI />
    </>
  );
};

export { FAQ };
